import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from "./Functions/scrollToTop";
import Login from './NewComponents/Pages/Login/Login';
import NavBar from './NewComponents/Main/NavBar';
import Dashboard from './NewComponents/Pages/Dashboard/Dashboard';

export default function App() {
    /* Base App function - holds the navigation between each page.
  
    - Router | Used to navigate between each page. 
    Where the website path goes to the specific page
  
    - ScrollToTop | When moving between pages, this function
    ensures the page always scrolls back to the top.
    
    - Switch (Route) | Used to define each page (login and dashboard) 
    */

    return (
        <>
            <Router>
                <ScrollToTop />
                <NavBar/>
                <Switch>
                    <Route path='/login' component={Login} />
                    <Route path='/' component={() => <Dashboard />} />
                </Switch>
            </Router>
        </>
    );
}