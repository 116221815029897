import React, { useEffect } from "react";
import { useState } from "react";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { deleteLandlordProperties, deleteUserFinal, makeUserCreateAccount, } from "../DatabaseService";

export default function SettingsPage({user}) {
    /* View that each user sees that gives them the ability to delete account and
    make config changes to their profile. Such as app notifications and deleting of data. */

    const [deleteDataBool, setDeleteDataBool] = useState(false);
    const [deleteAccountBool, setDeleteAccountBool] = useState(false);
    const [deleteDataPassword, setDeleteDataPassword] = useState('');
    const [deleteAccountPassword, setDeleteAccountPassword] = useState('');

    function deleteLandlordData(fullDelete) {
        deleteLandlordProperties()
            .then(() => {
                if (fullDelete) {
                    console.log('Deleting Account Data')
                    deleteUserFinal(global.user)
                    .then(() => {
                        window.location.href = '/login';
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                } else {
                    makeUserCreateAccount()
                        .catch(error => {
                            console.error(error);
                        })
                }
            })
            .catch(error => {
                console.error(error);
            })
    };

    function deleteCurrentUser(fullDelete) {
        try {
            console.log('Deleting User')
            const credential = EmailAuthProvider.credential(
                user.email,
                fullDelete ? deleteAccountPassword : deleteDataPassword,
            );
            reauthenticateWithCredential(user, credential)
                .then(() => {
                    if (global.user.userType === 'Landlord') {
                        deleteLandlordData(fullDelete)
                    } else if (global.user.userType === 'Tenant') {
                        deleteUserFinal(global.user)
                            .then(() => {
                                console.log('User Deleted')
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    wrongPasswordAlert()
                });
        } catch (error) {
            console.error(error);
        }
    };

    function deleteDataAlert() {
        if (window.confirm('Are you sure you want to reset your data?')) {
            deleteCurrentUser(false)
        }
    }

    function deleteAccountAlert() {
        if (window.confirm('Are you sure you want to delete your account?')) {
            deleteCurrentUser(true)
        }
    }

    function wrongPasswordAlert() {
        window.alert('Incorrect password, please try again.')
    }

    useEffect(() => {
        console.log('User data:', user)
    }, [user])

    return (
        <div className="dashboardContainer" >
            <div className="dashboardScroll">
                <div className="section-title">
                    <h2>Account Settings</h2>
                </div>

                { user !== undefined && user.userType !== 'CreateAccount' &&
                    <div onClick={() => { setDeleteDataBool(!deleteDataBool) }} className="dashboardButton" style={{ padding: 15, borderRadius: 10, backgroundColor: '#8B0000', width: 400, marginBottom: 20 }}>
                        <h2 style={{ color: 'white' }}>
                            <>Reset Account</>
                        </h2>
                    </div>
                }

                {deleteDataBool &&
                    <input
                        type="password"
                        placeholder='Re-Enter Password...'
                        className='loginInput'
                        style={{ width: 400, marginRight: 50, marginBottom: 20 }}
                        value={deleteDataPassword}
                        onChange={(password) => setDeleteDataPassword(password.target.value)}
                    />
                }

                {deleteDataPassword !== '' &&
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <div onClick={() => { deleteDataAlert() }} className="dashboardButton" style={{ padding: 15, borderRadius: 10, backgroundColor: '#8B0000', width: 400, marginBottom: 20 }}>
                            <h2 style={{ color: 'white' }}>Confirm</h2>
                        </div>
                    </div>
                }

                <div onClick={() => { setDeleteAccountBool(!deleteAccountBool) }} className="dashboardButton" style={{ padding: 15, borderRadius: 10, backgroundColor: '#8B0000', width: 400 }}>
                    <h2 style={{ color: 'white' }}>{<>Delete Account</>}</h2>
                </div>

                {deleteAccountBool &&
                    <input
                        type="password"
                        placeholder='Re-Enter Password...'
                        className='loginInput'
                        style={{ width: 400, marginRight: 50, marginTop: 20 }}
                        value={deleteAccountPassword}
                        onChange={(password) => setDeleteAccountPassword(password.target.value)}
                    />
                }

                {deleteAccountPassword !== '' &&
                    <div style={{ flexDirection: 'row', display: 'flex', }}>
                        <div onClick={() => { deleteAccountAlert() }} className="dashboardButton" style={{ padding: 15, borderRadius: 10, backgroundColor: '#8B0000', width: 400, marginTop: 20 }}>
                            <h2 style={{ color: 'white' }}>Confirm</h2>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}