import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA83hduNiMBoM0SFwl649EdnE7haCRHHaU",
    authDomain: "fixmyflat-dev.firebaseapp.com",
    projectId: "fixmyflat-dev",
    storageBucket: "fixmyflat-dev.appspot.com",
    messagingSenderId: "969225151334",
    appId: "1:969225151334:web:cb3eb39556f2cb8b0c9ea7",
    measurementId: "G-KQ4KNC4B35"
};

// Sets up the firebase functions to be used in the rest of the application.
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIRESTORE_DB = getFirestore(FIREBASE_APP);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const FIREBASE_STORAGE = getStorage(FIREBASE_APP);