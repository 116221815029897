import React, { useState, useEffect } from 'react';
import { FIREBASE_AUTH, FIRESTORE_DB } from '../../Functions/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { isMobile } from 'react-device-detect';
import { saveAccountData } from '../../Functions/databaseService';
import './dashboard.css';
import SettingsPage from '../Settings/SettingsPage';

export default function Dashboard() {
    /* The base page which displays all the relavent pages to each user type */
    const [user, setUser] = useState(undefined);
    const [mobileNav, setMobileNav] = useState(false);

    // Checks to see if the dashboard is being run on a mobile
    useEffect(() => {
        if (isMobile) {
            setMobileNav(true);
        } else {
            setMobileNav(false);
        }
    }, []);

    useEffect(() => {
        // Checks if the user has recently logged in, and then allows them not needing to relog in.
        const unsubscribeFromAuthStateChanged = onAuthStateChanged(FIREBASE_AUTH, (currentUser) => {
            
            if (currentUser && getAuth().currentUser.emailVerified) {
                setUser(currentUser);
                accountCheck(currentUser);
            } else {
                setUser(undefined);
                window.location.href = '/login';
            }
        })

        return unsubscribeFromAuthStateChanged
    })

    function accountCheck(user) {
        console.log('Checking Data')
        // Checks what type of account has logged into the dashboard
        const docRef = doc(FIRESTORE_DB, "users", user.uid);
        getDoc(docRef)
            .then((document) => {
                saveAccountData(document)
                console.log(document.data())
            })
            .catch((error) => {
                console.log('Error getting user data', error);
            })
    }

    return (
        <div>
            {!mobileNav && (
                <>
                    {user !== undefined && (
                        <>
                            <SettingsPage user={user}/>
                        </>
                    )}
                </>
            )}
        </div>
    );
}