import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

import { FIREBASE_AUTH, FIRESTORE_DB } from '../../Functions/firebase';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { saveAccountData } from '../../Functions/databaseService';

export default function NavBar() {
    /* Navbar is used to access the login and logout buttons. */

    const [checkedUser, setCheckedUser] = useState(false);
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        // Checks if the user has logged in and then goes to the dashboard
        const unsubscribeFromAuthStateChanged = onAuthStateChanged(FIREBASE_AUTH, (currentUser) => {
            if (currentUser && getAuth().currentUser.emailVerified) {
                setUser(currentUser);
                accountCheck(currentUser)
            } else {
                // Otherwise go to the login page
                setUser(undefined);
                if (window.location.href === '/') {
                    window.location.href = '/login';
                }
            }
            setCheckedUser(true)
        })
        return unsubscribeFromAuthStateChanged
    })

    function logout() {
        // Logs the user out then goes to the login page
        signOut(FIREBASE_AUTH).then(() => {
            window.location.href = '/login';
        })
    }

    function accountCheck(user) {
        // Function to get the user's data and save it to the global variables
        const docRef = doc(FIRESTORE_DB, "userDatabase", user.uid);
        getDoc(docRef)
            .then((document) => {
                saveAccountData(document)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <div className='navParent'>
            <div className='navbar'>
                <div className='navbar-container'>
                    {/* TODO: Link to the normal website */}
                    <Link to={user === undefined ? '/login' : '/'} className={'navbar-logo'}>
                        FixMyFlat
                    </Link>

                    {/* Only display the button if the check has been made first */}
                    {checkedUser && (
                        (user !== undefined) ? (
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <div style={{ marginRight: 15 }}>
                                    <button className='navBarButton dashboardButton' onClick={() => { logout() }} style={{ width: 125 }}>
                                        Log Out
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <div style={{ marginRight: 15 }}>
                                    <button className='navBarButton dashboardButton' onClick={() => { window.location.href = '/login' }} style={{ width: 125 }}>
                                        Log In
                                    </button>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}