import { collection, deleteDoc, deleteField, doc, getDocs, query, updateDoc, where } from "@firebase/firestore";
import { FIREBASE_STORAGE, FIRESTORE_DB } from "./firebase";
import { deleteObject, listAll, ref } from "@firebase/storage";

export function deletePropertyChats(propertyId) {
    // Deletes all messages in a property
    return new Promise((resolve, reject) => {
        let chatRef = query(collection(FIRESTORE_DB, "chats"), where("propertyId", "==", propertyId));
        getDocs(chatRef)
            .then((querySnapshot) => {
                var chatNumber = 0
                if (querySnapshot.docs.length === 0) {
                    resolve()
                }
                querySnapshot.docs.forEach((document) => {
                    const docRef = doc(FIRESTORE_DB, "chats", document.data().id);
                    deleteDoc(docRef)
                        .then(() => {
                            chatNumber += 1
                            if (chatNumber === querySnapshot.docs.length) {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function deleteIssueImages(propertyId, issueId) {
    // Deletes an issue's images
    return new Promise((resolve, reject) => {
        const string = '/' + propertyId + '/' + issueId;
        const reference = ref(FIREBASE_STORAGE, string)

        listAll(reference)
            .then((res) => {
                var imageNumber = 0
                if (res.items.length === 0) {
                    resolve()
                }
                res.items.forEach((itemRef) => {
                    const deleteRef = ref(FIREBASE_STORAGE, string + "/" + itemRef.name);
                    deleteObject(deleteRef)
                        .then(() => {
                            imageNumber += 1
                            if (imageNumber === res.items.length) {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        });
                });
            }).catch((error) => {
                reject(error)
            });
    })
}

export function deletePropertyIssues(property) {
    // Function which deletes the issues from the property as well as the images associated with them.
    return new Promise((resolve, reject) => {
        const issueRef = query(collection(FIRESTORE_DB, "issues"), where("propertyId", "==", property.id));
        getDocs(issueRef)
            .then((querySnapshot) => {
                var issueNum = 0
                if (querySnapshot.docs.length === 0) {
                    resolve()
                }
                querySnapshot.docs.forEach((document) => {
                    deleteIssueImages(property.id, document.data().id)
                        .then(() => {
                            const docRef = doc(FIRESTORE_DB, "issues", document.data().id);
                            deleteDoc(docRef)
                                .then(() => {
                                    issueNum += 1
                                    if (querySnapshot.docs.length === issueNum) {
                                        resolve()
                                    }
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            })
            .catch((error) => {
                reject(error);
            });
    })
}

function deletePropertyImage(property) {
    // Simple function deleting the image associated with a property
    return new Promise((resolve, reject) => {
        const storageString = String(property.id) + "/" + String("PropertyPhoto.jpg");
        const deleteRef = ref(FIREBASE_STORAGE, storageString);
        deleteObject(deleteRef)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                console.log(error)
                resolve()
            })
    })
}

export function deletePropertyData(property) {
    /* If a property is deleted by the landlord
    Remove all data: 
    - Property Image
    - Property Issues
    - Property Chats
    - Property Access from tenants */
    return new Promise((resolve, reject) => {
        const docRef = doc(FIRESTORE_DB, "properties", property.id);
        deleteDoc(docRef)
            .then(() => {
                deletePropertyImage(property)
                deletePropertyIssues(property)
                    .then(() => {
                        deletePropertyChats(property.id)
                            .then(() => {
                                revokeTenantAccess(property.id)
                                    .then(() => {
                                        resolve()
                                    })
                            })
                            .catch(error => {
                                reject(error)
                            })
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            .catch(error => {
                reject(error)
            })
    })
}

export function revokeTenantAccess(propertyId) {
    // Removes all tenant access from a property and reset their accounts.
    return new Promise((resolve, reject) => {
        const userRef = query(collection(FIRESTORE_DB, "userDatabase"), where("propertyId", "==", propertyId));
        getDocs(userRef)
            .then((querySnapshot) => {
                var tenantNumber = 0
                if (querySnapshot.docs.length === 0) {
                    resolve()
                }

                querySnapshot.docs.forEach((document) => {
                    const docRef = doc(FIRESTORE_DB, "userDatabase", document.data().accountId);
                    updateDoc(docRef, {
                        userType: 'Standard',
                        stage: 0,
                        propertyId: deleteField(),
                    })
                        .then(() => {
                            tenantNumber += 1
                            if (tenantNumber === querySnapshot.docs.length) {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function revokeTenantAccount(userId) {
    // Removes the access of a tenant to a property and resets their account back to standard
    return new Promise((resolve, reject) => {
        const tenantRef = doc(FIRESTORE_DB, "userDatabase", userId);
        var data = {
            userType: 'Standard',
            stage: 0,
            propertyId: deleteField()
        }

        updateDoc(tenantRef, data)
            .then(() => {
                resolve()
            })
            .error((error) => {
                reject(error)
            })
    })
}

export function deleteTenantChats(propertyId) {
    // When deleting a user, the tenant's chats are deleted for that property
    return new Promise((resolve, reject) => {
        var chatsRef = collection(FIRESTORE_DB, "chats")
        chatsRef = query(chatsRef, where("propertyId", "==", propertyId));
        chatsRef = query(chatsRef, where("type", "==", 'Tenant'));
        getDocs(chatsRef)
            .then((querySnapshot) => {
                var chatsDeleted = 0
                querySnapshot.forEach((document) => {
                    const docRef = doc(FIRESTORE_DB, "chats", document.data().id);
                    deleteDoc(docRef)
                        .then(() => {
                            chatsDeleted += 1
                            if (chatsDeleted === querySnapshot.length) {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        })
                })
            })
            .catch(error => {
                reject(error);
            })
    })
}

export function saveAccountData(document) {
    // Function to obtain the user's data from the database and add it to the global variables
    if (document.data() !== undefined) {
        const data = document.data()
        global.user = data
    }
}

export function seenObject(docRef, userType) {
    // Function to set an issue or chat to be seen by either the tenant or landlord
    return new Promise((resolve, reject) => {
        var data = {}
        if (userType === 'Landlord') {
            data = { landlordSeen: true };
        } else if (userType === 'Tenant') {
            data = { tenantSeen: true };
        }

        updateDoc(docRef, data)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    })
}