import { collection, deleteDoc, deleteField, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { FIREBASE_STORAGE, FIRESTORE_DB } from "../Functions/firebase";
import { deleteUser, getAuth } from "firebase/auth";
import { deleteObject, listAll, ref } from "firebase/storage";

export function revokeUserAccess(userId, selfDelete) {
    return new Promise((resolve, reject) => {
        const tenantRef = doc(FIRESTORE_DB, 'users', userId);
        const data = {
            userType: 'Tenant',
            stage: 'UserInfo',
            propertyId: deleteField(),
            landlordId: deleteField(),
            accepted: false
        }

        updateDoc(tenantRef, data)
            .then(() => {
                deleteTenantChats(userId)
            })
            .then(() => {
                if (selfDelete) {
                    const property = getPropertyFromId(global.user.landlordId ?? global.user.accountId, global.user.propertyId)
                    const address = property.address[0] + property.address[1]
                    const username = global.user.firstName + ' ' + global.user.lastName;
                    const message = username + ' has left the property'
                    getDoc(doc(FIRESTORE_DB, 'users', global.user.landlordId))
                        .then((document) => {
                            const data = document.data();
                            if (data.pushToken !== undefined && data.appNotify === true) {
                                sendPushNotification(data.pushToken, address, message);
                            }
                        })
                    createNotification(address, message, global.user.accountId, global.user.landlordId)
                } else {
                    const userDoc = doc(FIRESTORE_DB, 'users', userId);
                    getDoc(userDoc)
                        .then((document) => {
                            if (document.data().pushToken !== undefined && document.data().appNotify === true) {
                                sendPushNotification(document.data().pushToken, 'Access Revoked', 'Your access to your property has been removed')
                            }
                        })
                }
                resolve()
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export async function deleteTenantChats(accountId) {
    return new Promise((resolve, reject) => {
        var chatsRef = collection(FIRESTORE_DB, 'chats')
        chatsRef = query(chatsRef, where('accountId', '==', accountId));
        chatsRef = query(chatsRef, where('type', '==', 'Tenant'));
        getDocs(chatsRef)
            .then((querySnapshot) => {
                var chatsDeleted = 0
                querySnapshot.forEach((document) => {
                    const docRef = doc(FIRESTORE_DB, 'chats', document.data().id);
                    deleteDoc(docRef)
                        .then(() => {
                            chatsDeleted += 1
                            if (chatsDeleted === querySnapshot.length) {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        })
                })
            })
            .catch(error => {
                reject(error);
            })
    })
};


export async function sendPushNotification(expoPushToken, title, body) {
    const message = {
        to: expoPushToken,
        sound: 'default',
        title: title,
        body: body,
    };

    await fetch('https://exp.host/--/api/v2/push/send', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
    });
}


export async function createNotification(title, message, fromId, toId, type, propertyId, landlordId) {
    console.log('Creating notification')
    return new Promise((resolve, reject) => {
        const notificationRef = doc(collection(FIRESTORE_DB, 'notifications'));
        const data = {
            id: notificationRef.id,
            title: title,
            message: message,
            fromId: fromId,
            toId: toId,
            dateTime: Date.now(),
            type: type,
            propertyId: propertyId,
            landlordId: landlordId
        }
        setDoc(notificationRef, data)
            .then(() => {
                resolve()
            })
            .catch((error) => {
                console.log('Error creating notification:', error)
                reject(error)
            })
    })
}

export async function getPropertyFromId(landlordId, propertyId) {
    return new Promise((resolve, reject) => {
        const propertyRef = collection(FIRESTORE_DB, 'properties');
        const propertyQuery = query(propertyRef, where('landlordId', '==', landlordId));
        getDocs(propertyQuery)
            .then((docs) => {
                console.log('Getting property from id:', propertyId)
                docs.forEach(doc => {
                    if (doc.data().id === propertyId) {
                        resolve(doc.data());
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                reject()
            })
    })
}

export async function deleteUserFinal(user) {
    return new Promise((resolve, reject) => {
        console.log('Deleting user:', user.accountId)
        const userRef = doc(FIRESTORE_DB, 'users', user.accountId);
        deleteDoc(userRef)
            .then(() => {
                deleteAuthUser()
                    .then(() => {
                        console.log('User deleted')
                        global.user = undefined
                        resolve()
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
            .catch(error => {
                reject(error)
            })
    })
}

export async function deleteAuthUser() {
    return new Promise((resolve, reject) => {
        const user = getAuth().currentUser;
        deleteUser(user).then(() => {
            resolve()
        }).catch((error) => {
            reject(error)
        });
    });
};

export async function deleteLandlordProperties() {
    try {
        const propertyRef = query(collection(FIRESTORE_DB, 'properties'), where('landlordId', '==', global.user.accountId));
        const querySnapshot = await getDocs(propertyRef);

        if (querySnapshot.empty) {
            return;
        }

        const deletePromises = querySnapshot.docs.map(async (document) => {
            const propertyId = document.data().id;

            await deleteLandlordIssues(propertyId);
            await deleteChats(propertyId);
            await deleteTenantAccess(global.user.accountId, propertyId);
            await deleteLandlordNotifications(propertyId);

            const docRef = doc(FIRESTORE_DB, 'properties', propertyId);
            await deleteDoc(docRef);

            const storageString = `${propertyId}/PropertyPhoto.jpg`;
            const deleteRef = ref(FIREBASE_STORAGE, storageString);

            try {
                await deleteObject(deleteRef);
            } catch (error) {
                if (error.code !== 'storage/object-not-found') {
                    console.log(`Error deleting storage object for property ${propertyId}:`, error);
                } else {
                    console.log(`No storage object found for property ${propertyId}, skipping delete.`);
                }
            }
        });

        await Promise.all(deletePromises);
    } catch (error) {
        console.error('Error deleting landlord properties:', error);
        throw error;
    }
}

export function deleteLandlordIssues(propertyId) {
    return new Promise((resolve, reject) => {
        const issueRef = query(collection(FIRESTORE_DB, 'issues'), where('landlordId', '==', global.user.accountId));
        getDocs(issueRef)
            .then((querySnapshot) => {
                var issueNumber = 0

                if (querySnapshot.docs.length === 0) {
                    resolve()
                }

                querySnapshot.docs.forEach((document) => {
                    const docRef = doc(FIRESTORE_DB, 'issues', document.data().id);

                    deleteIssueImages(propertyId, document.data().id)
                        .then(() => {
                            deleteDoc(docRef)
                                .then(() => {
                                    issueNumber += 1;
                                    if (issueNumber === querySnapshot.docs.length) {
                                        resolve()
                                    }
                                })
                                .catch((error) => {
                                    reject(error)
                                });
                        })
                });
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export function deleteChats(propertyId) {
    return new Promise((resolve, reject) => {
        console.log('Deleting chats')
        let chatRef = query(collection(FIRESTORE_DB, 'chats'), where('landlordId', '==', global.user.accountId));
        getDocs(chatRef)
            .then((querySnapshot) => {
                const chatLen = querySnapshot.docs.filter(doc => doc.data().propertyId === propertyId).length;
                if (chatLen === 0) {
                    console.log('No chats found')
                    resolve()
                } else {
                    console.log('Chats found:', chatLen)
                }

                var chatsDeleted = 0
                querySnapshot.docs.forEach((document) => {
                    if (document.data().propertyId === propertyId) {
                        const docRef = doc(FIRESTORE_DB, 'chats', document.data().id);
                        deleteDoc(docRef)
                            .then(() => {
                                chatsDeleted += 1;
                                if (chatsDeleted === chatLen) {
                                    resolve();
                                }
                            })
                            .catch((error) => {
                                resolve(error);
                            });
                    }
                });
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export async function deleteTenantAccess(landlordId, propertyId, selfDelete = false, fullDelete = false, user = undefined) {
    return new Promise((resolve, reject) => {
        console.log('Deleting tenant access')
        const userRef = collection(FIRESTORE_DB, 'users')
        const tenantRef = query(userRef, where('landlordId', '==', landlordId));
        getDocs(tenantRef)
            .then((querySnapshot) => {
                const userNum = querySnapshot.docs.filter(doc => doc.data().propertyId === propertyId).length
                var usersUpdated = 0

                if (userNum === 0) {
                    console.log('No users found')
                    resolve()
                } else {
                    console.log('Users found:', userNum)
                }

                querySnapshot.docs.forEach((document) => {
                    revokeUserAccess(document.id, selfDelete, fullDelete)
                        .then(() => {
                            usersUpdated += 1
                            if (usersUpdated === userNum) {
                                resolve()
                            }
                        })
                        .catch(error => {
                            reject(error)
                        })
                });
            })
            .catch(error => {
                reject(error)
            })
    })
}


export function deleteIssueImages(propertyId, issueId) {
    return new Promise((resolve, reject) => {
        const string = '/' + propertyId + '/' + issueId;
        const reference = ref(FIREBASE_STORAGE, string)

        listAll(reference)
            .then((res) => {
                var imageNumber = 0
                if (res.items.length === 0) {
                    resolve()
                }

                res.items.forEach((itemRef) => {
                    const deleteRef = ref(FIREBASE_STORAGE, string + '/' + itemRef.name);
                    deleteObject(deleteRef)
                        .then(() => {
                            imageNumber += 1;
                            if (imageNumber === res.items.length) {
                                resolve()
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        });
                });
            }).catch((error) => {
                reject(error)
            });
    })
}

export function makeUserCreateAccount() {
    return new Promise((resolve, reject) => {
        const docRef = doc(FIRESTORE_DB, 'users', global.user.accountId);

        const data = {
            accountId: global.user.accountId,
            username: global.user.username,
            email: global.user.email,
            firstName: global.user.firstName,
            lastName: global.user.lastName,
            userType: 'CreateAccount',
            stage: 'UserInfo',
        }

        setDoc(docRef, data)
            .then(() => {
                global.user.userType = 'CreateAccount';
                global.user.userStage = 'UserInfo';
                global.user.accepted = undefined;
                window.location.href = '/login';
                resolve()
            }).catch(error => {
                reject(error);
            });
    });
}

export async function deleteLandlordNotifications(propertyId) {
    try {
        const notificationRef = query(collection(FIRESTORE_DB, 'notifications'), where('landlordId', '==', global.user.accountId));
        const querySnapshot = await getDocs(notificationRef);

        const deletePromises = querySnapshot.docs
            .filter(doc => doc.data().propertyId === propertyId)
            .map(async (document) => {
                const docRef = doc(FIRESTORE_DB, 'notifications', document.data().id);
                await deleteDoc(docRef);
            });

        await Promise.all(deletePromises);
    } catch (error) {
        console.error('Error deleting landlord notifications:', error);
        throw error;
    }
}
