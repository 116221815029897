import React, { useState } from 'react';
import './Login.css';
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { FIREBASE_AUTH } from '../../../Functions/firebase.js';
import { saveAccountData } from '../../Functions/databaseService.js';
import { doc, getDoc } from 'firebase/firestore';
import { FIRESTORE_DB } from '../../Functions/firebase.js';

export default function Login() {
    /* Page which requires the user's login details to go into their account.
    This page also acts as the create account site. 
    */
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleKeyDown = (event) => {
        // If the user presses enter it will login too
        if (event.key === 'Enter') {
            loginClick()
        }
    }

    function loginClick() {
        // If the user clicks login or sign up - this function handles both scenarios
        if (email !== "" && password !== "") {
            // Login with already signed up user
            signInWithEmailAndPassword(FIREBASE_AUTH, email, password)
                .then(() => {
                    const user = getAuth().currentUser
                    if (user.emailVerified) {
                        console.log('User Logged In')
                        
                        const docRef = doc(FIRESTORE_DB, "users", user.uid);
                        getDoc(docRef)
                        .then((document) => {
                            console.log(document.data())
                            window.location.href = '/';
                            saveAccountData(document)
                        })
                        .catch((error) => {
                            console.log('Error getting user data', error);
                        })
                    } else {
                        window.alert("Please verify your email using the link sent to you - please look in your spam folder if you haven't recieved it.")

                        sendEmailVerification(user)
                            .then(() => {
                                window.location.href = '/login';
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                })
                .catch((error) => {
                    // window.alert('Incorrect Details - Please ensure your email and password are correctly inputted.');
                    console.log(error);
                })
        }
    }

    return (
        <div className="container loginContainer">
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginTop: 125, paddingTop: 15 }}>
                <h1 className="main-heading">
                    <>Log into your <span>FixMyFlat.</span> Account</>
                </h1>

                <h2 className='dashboardSubTitleBlack' style={{ width: 400, alignItems: 'flex-start', marginTop: 20 }}>
                    Email
                </h2>

                <input
                    type='email'
                    placeholder='Email...'
                    id='email'
                    name='email'
                    className='loginInput'
                    style={{ width: 400, marginBottom: 20 }}
                    value={email}
                    onChange={(email) => setEmail(email.target.value)}
                    onKeyDown={handleKeyDown}
                />

                <h2 className='dashboardSubTitleBlack' style={{ width: 400, alignItems: 'flex-start' }}>
                    Password
                </h2>

                <input
                    type='password'
                    placeholder='Password...'
                    id='password'
                    name='password'
                    className='loginInput'
                    style={{ width: 400, marginBottom: 20 }}
                    value={password}
                    onKeyDown={handleKeyDown}
                    onChange={(password) => setPassword(password.target.value)} />

                <button className="loginButton view_more_btn dashboardButton" style={{ width: 400 }} onClick={loginClick}>
                    <>Log In</>
                </button>
            </div>
        </div>
    );
}